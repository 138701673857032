import React, { Component } from 'react';
import "../style/Page.css"
import "../../App.css"
import crane_1 from "../Projects/projects_img/crane_1.png"
import crane_2 from "../Projects/projects_img/crane_2.png"
import crane_3 from "../Projects/projects_img/crane_3.png"
import crane_4 from "../Projects/projects_img/crane_4.png"
import AliceCarousel from "react-alice-carousel";
import { t } from "i18next";


class IntemsPIK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        let img = [crane_1, crane_2, crane_3, crane_4]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            <div className="title_block_text">
                                <div className='header_page light'>{t("smartCrane.header")}</div>
                                <div className="mini-header light">{t("smartCrane.title")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="header_page">{t("smartCrane.title_1.name")}</div>
                        <div className="main_text">{t("smartCrane.title_1.text")}</div>
                        <div className="header_page">{t("smartCrane.title_2.name")}</div>
                        <div className="main_text">
                            <div className="block_container">
                                <div>
                                    <div className="header_block">{t("smartCrane.title_2.1.header")}</div>
                                    <div className="text_block">{t("smartCrane.title_2.1.body")}</div>
                                </div>
                                <div>
                                    <div className="header_block">{t("smartCrane.title_2.2.header")}</div>
                                    <div className="text_block">{t("smartCrane.title_2.2.body")}</div>
                                </div>
                                <div>
                                    <div className="header_block">{t("smartCrane.title_2.3.header")}</div>
                                    <div className="text_block">{t("smartCrane.title_2.3.body")}</div>
                                </div>
                                <div>
                                    <div className="header_block">{t("smartCrane.title_2.4.header")}</div>
                                    <div className="text_block">{t("smartCrane.title_2.4.body")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="header_page">{t("smartCrane.title_3.name")}</div>
                        <div className="main_text">{t("smartCrane.title_3.text")}</div>
                        <div className="header_page">{t("smartCrane.title_4.name")}</div>
                        <div className="main_text">{t("smartCrane.title_4.text")}</div>
                        <div className="img_container">
                            <img className="page_img page_img_png" src={crane_1} alt="crane1" />
                            <img className="page_img page_img_png" src={crane_2} alt="crane2" />
                        </div>
                        <div className="header_page">{t("smartCrane.title_5.name")}</div>
                        <div className="main_text">
                            {t("smartCrane.title_5.text.1")}<a href="https://semargl.pro/">{t("smartCrane.title_5.text.a")}</a>{t("smartCrane.title_5.text.2")}
                        </div>
                        <div className="header_page_center header_page">{t("gallery")}</div>
                        <div className="img_container">
                            <AliceCarousel>
                                {img.map((item, index) =>
                                    <div className='page_img_container'>
                                        <img className="page_img" src={item} alt={img.index} />
                                    </div>

                                )}
                            </AliceCarousel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IntemsPIK;
// const React = require('react');
import React from 'react';
import "i18next";

import technology_1 from '../img/technology/technology_1.png'
import technology_2 from '../img/technology/technology_2.png'
import technology_3 from '../img/technology/technology_3.png'
import technology_4 from '../img/technology/technology_4.png'
import technology_5 from '../img/technology/technology_5.png'

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-router-dom";
import { t } from "i18next";
import './style/Technology.css'

export default class Technology extends React.Component {
    render() {
        let projects = [
            {
                id: 1, name: t('technologies.1.name'),
                title: {
                    t_1: t('technologies.1.1'),
                    t_2: t('technologies.1.2'),
                    t_3: t('technologies.1.3'),
                    t_4: t('technologies.1.4'),
                },
                img: technology_1
            },
            {
                id: 4, name: t('technologies.4.name'),
                title: {
                    t_1: t('technologies.4.1'),
                    t_2: t('technologies.4.2'),
                    t_3: t('technologies.4.3'),
                },
                img: technology_4
            },
            {
                id: 2, name: t('technologies.2.name'),
                title: {
                    t_1: t('technologies.2.1'),
                    t_2: t('technologies.2.2'),
                    t_3: t('technologies.2.3'),
                    t_4: t('technologies.2.4'),
                    t_5: t('technologies.2.5'),
                    t_6: t('technologies.2.6'),
                },
                img: technology_2
            },
            {
                id: 3, name: t('technologies.3.name'),
                title: {
                    t_1: t('technologies.3.1'),
                    t_2: t('technologies.3.2'),
                    t_3: t('technologies.3.3'),
                    t_4: t('technologies.3.4'),
                    t_5: t('technologies.3.5'),
                    t_6: t('technologies.3.6'),
                    t_7: t('technologies.3.7'),
                    t_8: t('technologies.3.8'),
                },
                img: technology_3
            },
            {
                id: 5, name: t('technologies.5.name'),
                title: {
                    t_1: t('technologies.5.1'),
                    t_2: t('technologies.5.2'),
                },
                img: technology_5
            },

        ]
        return (
            <div>


                <div className='technology-container'>
                    <div className='technologies'>
                        <AliceCarousel controlsStrategy="responsive, alternate" responsive={{
                            0: {
                                items: 1,
                            },
                            780: {
                                items: 2,
                                itemsFit: 'fill',
                            },
                            1280: {
                                items: 3,
                                itemsFit: 'fill',
                            },
                        }}>
                            {projects.map((item, index) =>
                                <Link to={item.href}>
                                    <div key={index}>
                                        <div className='technology'>
                                            <div className='rectangle-technology'>
                                                <img className='project-img' src={item.img} />
                                                <div className='title-technology'>
                                                    {item.title.t_1}<br />
                                                    {item.title.t_2}<br />
                                                    {item.title.t_3}<br />
                                                    {item.title.t_4}<br />
                                                    {item.title.t_5}<br />
                                                    {item.title.t_6}<br />
                                                    {item.title.t_7}<br />
                                                    {item.title.t_8}</div>
                                            </div>
                                            <div className='name-technology'>{item.name}</div>
                                        </div>

                                    </div>
                                </Link>
                            )}
                        </AliceCarousel>
                    </div>
                </div>
            </div>
        );
    }
}


import React, { Component } from 'react';
import "../style/Page.css"
import "../../App.css"
import lab_1 from "./projects_img/lab_1.png";
import lab_2 from "./projects_img/lab_2.png";
import lab_3 from "./projects_img/lab_3.png";
import lab_4 from "./projects_img/lab_4.png";
import { t } from "i18next";
import Footer from '../Footer';
import AliceCarousel from "react-alice-carousel";

class HotLab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }

    }
    render() {
        window.scrollTo(0, 0)
        let img = [lab_1, lab_2, lab_3, lab_4]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            <div className="title_block_text">
                                <div className='header_page light'>{t("hotLab.header")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <p className="header_page_center header_page_mini">{t("hotLab.title_1.name")}</p>
                        <div className="main_text">{t("hotLab.title_1.text")}</div>
                        <div className='img_container'>
                            <img className='page_img' src={lab_1}></img>
                        </div>


                        <p className="header_page_center header_page_mini">{t("hotLab.title_2.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                    <tr>
                                        <td className="table_title">{t("hotLab.title_2.text.row_1.1")}</td>
                                        <td>{t("hotLab.title_2.text.row_1.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("hotLab.title_2.text.row_2.1")}</td>
                                        <td>{t("hotLab.title_2.text.row_2.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("hotLab.title_2.text.row_3.1")}</td>
                                        <td>{t("hotLab.title_2.text.row_3.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("hotLab.title_2.text.row_4.1")}</td>
                                        <td>{t("hotLab.title_2.text.row_4.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                            
                                    <tr>
                                        <td className="table_title">{t("hotLab.title_2.text.row_6.1")}</td>
                                        <td>{t("hotLab.title_2.text.row_6.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("hotLab.title_2.text.row_7.1")}</td>
                                        <td>{t("hotLab.title_2.text.row_7.2")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <p className="table_title">{t("hotLab.title_2.text.row_1.1")}</p>
                                {t("hotLab.title_2.text.row_1.2")}
                                <p className="table_title">{t("hotLab.title_2.text.row_2.1")}</p>
                                {t("hotLab.title_2.text.row_2.2")}
                                <p className="table_title">{t("hotLab.title_2.text.row_3.1")}</p>
                                {t("hotLab.title_2.text.row_3.2")}
                                <p className="table_title">{t("hotLab.title_2.text.row_4.1")}</p>
                                {t("hotLab.title_2.text.row_4.2")}
                                <p className="table_title">{t("hotLab.title_2.text.row_6.1")}</p>
                                {t("hotLab.title_2.text.row_6.2")}
                                <p className="table_title">{t("hotLab.title_2.text.row_7.1")}</p>
                                {t("hotLab.title_2.text.row_7.2")}
                            </div>
                        </div>

                        <p className="header_page_center header_page_mini">{t("hotLab.title_3.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("hotLab.title_3.text.1")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_3.text.2")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_3.text.3")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_3.text.4")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("hotLab.title_4.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("hotLab.title_4.text.1")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_4.text.2")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_4.text.3")}</div></li>
                        </ul>


                        <p className="header_page_center header_page_mini">{t("hotLab.title_6.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("hotLab.title_6.text.1")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_6.text.2")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_6.text.3")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_6.text.4")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_6.text.5")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_6.text.6")}</div></li>
                        </ul>
                        

                        <p className="header_page_center header_page_mini">{t("hotLab.title_8.name")}</p>
                        <div className="main_text">{t("hotLab.title_8.text")}</div>

                        <p className="header_page_center header_page_mini">{t("hotLab.title_9.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("hotLab.title_9.text.1")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_9.text.2")}</div></li>
                            <li><div className="main_text">{t("hotLab.title_9.text.3")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("hotLab.title_10.name")}</p>
                        <div className="main_text">{t("hotLab.title_10.text")}</div>

                        {/*<p className="header_page_center header_page_mini">{t("hotLab.title_11.name")}</p>
                        <div className="main_text table_title">{t("hotLab.title_11.text.1")}</div>
                        <div className="block_container">
                            <div>
                                <a href="#"
                                    className="modal-btn download_btn"><i
                                        className="fa fa-download" aria-hidden="true"></i>{t("plc.title_doc.general")}</a>
                            </div>
                        </div>
                        <div className="main_text table_title">{t("hotLab.title_11.text.2")}</div>
                        <div className="block_container">
                            <div>
                                <a href="#"
                                    className="modal-btn download_btn"><i
                                        className="fa fa-download" aria-hidden="true"></i>{t("plc.title_doc.general")}</a>
                            </div>
                        </div>*/}



                        <div className="header_page_center header_page">{t("gallery")}</div>
                        <div className="img_container">
                            <AliceCarousel>
                                {img.map((item, index) =>
                                    <div className='page_img_container'>
                                        <img className="page_img" src={item} alt={img.index} />
                                    </div>

                                )}
                            </AliceCarousel>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default HotLab;
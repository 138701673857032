import React from 'react';
import Current from "./Current.jsx";
import Projects from "./Projects.jsx";
import Services from "./Services.jsx";
import Clients from "./Clients.jsx";
import Technology from "./Technology.jsx";
import Team from "./Team.jsx";
import Iframe from "react-iframe";
import { t } from "i18next";
import Documents from './Documents.jsx';
import Footer from './Footer.jsx';

const Main = () => {
    return (
        <div>
            <div className='wrapper current_wrapper'>
                <div className='container'>
                    {/*<div className='header light'>Продукты</div>*/}
                    <Current />
                </div>
            </div>
            <div className='wrapper_white' id='projects' >
                <div className='container'>
                    <div className='header dark'>{t("projects")}</div>
                    <Projects />
                </div>
            </div>
            <div className='wrapper' id='services'>
                <div className='container'>
                    <div className='header light'>{t("services")}</div>
                    <div className='mini-header light'>{t("service.title")}</div>
                    <Services />
                </div>
            </div>
            <div className='wrapper_white' id='clients'>
                <div className='container'>
                    <div className='header dark'>{t("clients")}</div>
                    <Clients />
                </div>
            </div>
            <div className='wrapper_dark' id='technology'>
                <div className='container'>
                    <div className='header light'>{t("technology")}</div>
                    <Technology />
                </div>
            </div>
            <div className='wrapper_white' id='about'>
                <div className='container'>
                    <div className='header dark'>{t("about_us")}</div>

                    <div className=''>
                        <div className='about'>
                            <div className='about-img-container'>
                                <div className='about-img' id='about-img-1'></div>
                            </div>
                            <div>
                                <div className="table_title about-name" id='val-name-1'>{t('aboutText.mission.name')}</div>
                                <div className="main_text about-text" id='val-text-1'>{t('aboutText.mission.text')}</div>
                            </div>
                        </div>
                        <div className='about'>
                            <div className='about-img-container'>
                                <div className='about-img' id='about-img-2'></div>
                            </div>
                            <div>
                                <div className="table_title about-name" id='val-name-2'>{t('aboutText.vision.name')}</div>
                                <div className="main_text about-text" id='val-text-2'>{t('aboutText.vision.text')}</div>
                            </div>
                        </div>
                        <div className='about'>
                            <div className='about-img-container'>
                                <div className='about-img' id='about-img-3'></div>
                            </div>
                            <div>
                                <div className="table_title about-name" id='val-name-3'>{t('aboutText.goals.name')}</div>
                                <div className="main_text about-text" id='val-text-31'>{t('aboutText.goals.text.1')}</div>
                                <div className="main_text about-text" id='val-text-32'>{t('aboutText.goals.text.2')}</div>
                                <div className="main_text about-text" id='val-text-33'>{t('aboutText.goals.text.3')}</div>
                                <div className="main_text about-text" id='val-text-34'>{t('aboutText.goals.text.4')}</div>
                            </div>
                        </div>
                    </div>


                    <div className="main_text">
                        {t('aboutText.text1')}
                    </div><br />
                    <div className="main_text">
                        {t('aboutText.text2')}
                    </div><br />
                    <div className="main_text">
                        {t('aboutText.text3')}
                    </div><br />
                </div>
            </div>

            <div className='wrapper-light' id='about'>
                <div className='container'>
                    <div className='header dark'>{t("aboutText.values.title")}</div>
                    <div className='values-container'>
                        <div className='values'>
                            <div className='value-rectangle'>
                                <div className='values-img-container'>
                                    <div className='values-img' id='val-img-1'></div>
                                </div>
                                <div className="table_title values-name" id='val-name-1'>{t('aboutText.values.1.name')}</div>
                                <div className="main_text values-text" id='val-text-1'>{t('aboutText.values.1.text')}</div>
                                <div className='values-arrow-container'>
                                    <div className='values-arrow'></div>
                                </div>
                            </div>
                        </div>
                        <div className='values'>
                            <div className='value-rectangle'>
                                <div className='values-img-container'>
                                    <div className='values-img' id='val-img-2'></div>
                                </div>
                                <div className="table_title values-name" id='val-name-2'>{t('aboutText.values.2.name')}</div>
                                <div className="main_text values-text" id='val-text-2'>{t('aboutText.values.2.text')}</div>
                                <div className='values-arrow-container'>
                                    <div className='values-arrow'></div>
                                </div>
                            </div>
                        </div>
                        <div className='values'>
                            <div className='value-rectangle'>
                                <div className='values-img-container'>
                                    <div className='values-img' id='val-img-3'></div>
                                </div>
                                <div className="table_title values-name" id='val-name-3'>{t('aboutText.values.3.name')}</div>
                                <div className="main_text values-text" id='val-text-3'>{t('aboutText.values.3.text')}</div>
                                <div className='values-arrow-container'>
                                    <div className='values-arrow'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='wrapper' id='doc'>
                <div className='container'>
                    <div className='header light'>{t("document")}</div>
                    <Documents />
                </div>
            </div>
            <div className='wrapper_white' id='team'>
                <div className='container'>
                    <div className='header dark'>{t("team")}</div>
                    <Team />
                </div>
            </div>

            {/*<div className='wrapper wrapper_1' id='pik'>
              <div className='container'>
                  <div className='header light'>IntemsLab-ПИК</div>
                  <div className='mini-header'>Прибор контрольно-измерительный. Сетевое устройство для измерения параметров электросети, а так же контроля состояния датчиков подключеных к нему.</div>
              </div>
          </div>*/}
            <div className='wrapper_white' id='contacts'>
                <div className='container'>
                    <div className='header dark'>{t("contacts")}</div>
                    <div className='contacts'>
                        <div className='contacts-col'>
                            <div className='map'><a
                                href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps"
                                className='map-text'>Санкт‑Петербург</a><a
                                    href="https://yandex.ru/maps/2/saint-petersburg/house/kantemirovskaya_ulitsa_12/Z0kYdQRhSEYOQFtjfXV5cnVqbQ==/?ll=30.334704%2C59.984747&utm_medium=mapframe&utm_source=maps&z=16.74"
                                    className='map-address'>Кантемировская улица, 12 —
                                    Яндекс Карты</a>
                                <Iframe url="https://yandex.ru/map-widget/v1/-/CCUjV4TPgC" width={window.innerWidth >= 920 ? "560 " : "300"} height={window.innerWidth >= 920 ? "400 " : "200"}
                                    frameBorder="0" allowFullScreen="true" style="position:relative;"></Iframe>
                            </div>
                        </div>
                        <div className='contacts-col'>
                            <div className='mini-header dark'>{t("contact.timetable")}</div>
                            <div className='mini-header dark'>{t("contact.phoneNumber")}</div>
                            <div className='mini-header dark'>{t("contact.eMail")}</div>
                            <div className='mini-header dark'>{t("contact.address")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Main;
import React, { Component } from 'react';
import "../style/Page.css"
import "../../App.css"
import AliceCarousel from "react-alice-carousel";
import chron_1 from "./projects_img/chron_1.jpg"
import chron_2 from "./projects_img/chron_2.png"
import chron_3 from "./projects_img/chron_3.jpg"
import chron_4 from "./projects_img/chron_4.jpg"
import chron_5 from "./projects_img/chron_5.jpg"
import { t } from "i18next";
import Footer from '../Footer';


class Chronos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        let img = [chron_1, chron_2, chron_3, chron_4, chron_5]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/*<img className="page_main_img" src={acc_img} alt="chronos"/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("chronos.header")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <p className="header_page_center header_page_mini">{t("chronos.title_1.name")}</p>
                        <div className="main_text">{t("chronos.title_1.text")}</div>
                        <div className='img_container'>
                            <img className='page_img' src={chron_2}></img>
                        </div>


                        <p className="header_page_center header_page_mini">{t("chronos.title_2.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                    <tr>
                                        <td className="table_title">{t("chronos.title_2.text.row_1.1")}</td>
                                        <td>{t("chronos.title_2.text.row_1.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("chronos.title_2.text.row_2.1")}</td>
                                        <td><div>{t("chronos.title_2.text.row_2.2.1")}</div>
                                            <div>{t("chronos.title_2.text.row_2.2.2")}</div>
                                            <div>{t("chronos.title_2.text.row_2.2.3")}</div>
                                            <div>{t("chronos.title_2.text.row_2.2.4")}</div>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("chronos.title_2.text.row_3.1")}</td>
                                        <td>{t("chronos.title_2.text.row_3.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("chronos.title_2.text.row_4.1")}</td>
                                        <td>{t("chronos.title_2.text.row_4.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("chronos.title_2.text.row_5.1")}</td>
                                        <td>{t("chronos.title_2.text.row_5.2")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <p className="table_title">{t("chronos.title_2.text.row_1.1")}</p>
                                {t("chronos.title_2.text.row_1.2")}
                                <p className="table_title">{t("chronos.title_2.text.row_2.1")}</p>
                                <ul><li>{t("chronos.title_2.text.row_2.2.1")}</li>
                                    <li>{t("chronos.title_2.text.row_2.2.2")}</li>
                                    <li>{t("chronos.title_2.text.row_2.2.3")}</li>
                                    <li>{t("chronos.title_2.text.row_2.2.4")}</li>
                                
                                </ul>
                                <p className="table_title">{t("chronos.title_2.text.row_3.1")}</p>
                                {t("chronos.title_2.text.row_3.2")}
                                <p className="table_title">{t("chronos.title_2.text.row_4.1")}</p>
                                {t("chronos.title_2.text.row_4.2")}
                                <p className="table_title">{t("chronos.title_2.text.row_5.1")}</p>
                                {t("chronos.title_2.text.row_5.2")}
                            </div>
                        </div>

                        <p className="header_page_center header_page_mini">{t("chronos.title_3.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("chronos.title_3.text.1")}</div></li>
                            <li><div className="main_text">{t("chronos.title_3.text.2")}</div></li>
                            <li><div className="main_text">{t("chronos.title_3.text.3")}</div></li>
                            <li><div className="main_text">{t("chronos.title_3.text.4")}</div></li>
                            <li><div className="main_text">{t("chronos.title_3.text.5")}</div></li>
                        </ul>



                        <p className="header_page_center header_page_mini">{t("chronos.title_7.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("chronos.title_7.text.1")}</div></li>
                            <li><div className="main_text">{t("chronos.title_7.text.2")}</div></li>
                            <li><div className="main_text">{t("chronos.title_7.text.3")}</div></li>
                            <li><div className="main_text">{t("chronos.title_7.text.4")}</div></li>
                            <li><div className="main_text">{t("chronos.title_7.text.5")}</div></li>
                            <li><div className="main_text">{t("chronos.title_7.text.6")}</div></li>
                            <li><div className="main_text">{t("chronos.title_7.text.7")}</div></li>
                        </ul>

                    

                        <p className="header_page_center header_page_mini">{t("chronos.title_9.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("chronos.title_9.text.1")}</div></li>
                            <li><div className="main_text">{t("chronos.title_9.text.2")}</div></li>
                        </ul>

                        <div className="header_page_center header_page">{t("gallery")}</div>
                        <div className="img_container">
                            <AliceCarousel>
                                {img.map((item, index) =>
                                    <div className='page_img_container'>
                                        <img className="page_img" src={item} alt={img.index} />
                                    </div>

                                )}
                            </AliceCarousel>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Chronos;
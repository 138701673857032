import React, { Component } from 'react';
import "../style/Page.css"
import "../../App.css"
import zaslon_1 from "./projects_img/zaslon_1.png"
import zaslon_2 from "./projects_img/zaslon_2.jpg"
import AliceCarousel from "react-alice-carousel";
import { t } from "i18next";
import Footer from '../Footer';


class Zaslon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        let img = [zaslon_1, zaslon_2]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/*<img className="page_main_img" src={acc_img} alt="zaslon"/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("zaslon.header")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <p className="header_page_center header_page_mini">{t("zaslon.title_1.name")}</p>
                        <div className="main_text">{t("zaslon.title_1.text")}</div>
                        <div className='img_container'>
                            <img className='page_img' src={zaslon_2}></img>
                        </div>

                        <p className="header_page_center header_page_mini">{t("zaslon.title_3.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("zaslon.title_3.text.1")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_3.text.2")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_3.text.3")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_3.text.4")}</div></li>

                        </ul>



                        <p className="header_page_center header_page_mini">{t("zaslon.title_6.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("zaslon.title_6.text.1")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_6.text.2")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_6.text.3")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_6.text.4")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_6.text.5")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_6.text.6")}</div></li>
                            <li><div className="main_text">{t("zaslon.title_6.text.7")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("zaslon.title_7.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                    <tr>
                                        <td className="table_title">{t("zaslon.title_7.text.row_1.1")}</td>
                                        <td>
                                            <ul>
                                                <li>{t("zaslon.title_7.text.row_1.2.1")}</li>
                                                <li>{t("zaslon.title_7.text.row_1.2.2")}</li>
                                                <li>{t("zaslon.title_7.text.row_1.2.3")}</li>
                                                <li>{t("zaslon.title_7.text.row_1.2.4")}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("zaslon.title_7.text.row_2.1")}</td>
                                        <td>
                                            <ul>
                                                <li>{t("zaslon.title_7.text.row_2.2.1")}</li>
                                                <li>{t("zaslon.title_7.text.row_2.2.2")}</li>
                                                <li>{t("zaslon.title_7.text.row_2.2.3")}</li>
                                                <li>{t("zaslon.title_7.text.row_2.2.4")}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("zaslon.title_7.text.row_3.1")}</td>
                                        <td>
                                            <ul>
                                                <li>{t("zaslon.title_7.text.row_3.2.1")}</li>
                                                <li>{t("zaslon.title_7.text.row_3.2.2")}</li>
                                                <li>{t("zaslon.title_7.text.row_3.2.3")}</li>
                                                <li>{t("zaslon.title_7.text.row_3.2.4")}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("zaslon.title_7.text.row_4.1")}</td>
                                        <td>
                                            <ul>
                                                <li>{t("zaslon.title_7.text.row_4.2.1")}</li>
                                                <li>{t("zaslon.title_7.text.row_4.2.2")}</li>
                                                <li>{t("zaslon.title_7.text.row_4.2.3")}</li>
                                                <li>{t("zaslon.title_7.text.row_4.2.4")}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("zaslon.title_7.text.row_5.1")}</td>
                                        <td>
                                            <ul>
                                                <li>{t("zaslon.title_7.text.row_5.2.1")}</li>
                                                <li>{t("zaslon.title_7.text.row_5.2.2")}</li>
                                                <li>{t("zaslon.title_7.text.row_5.2.3")}</li>
                                                <li>{t("zaslon.title_7.text.row_5.2.4")}</li>
                                            </ul>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div className="list_page">
                                <p className="table_title">{t("zaslon.title_7.text.row_1.1")}</p>
                                <ul>
                                    <li>{t("zaslon.title_7.text.row_1.2.1")}</li>
                                    <li>{t("zaslon.title_7.text.row_1.2.2")}</li>
                                    <li>{t("zaslon.title_7.text.row_1.2.3")}</li>
                                    <li>{t("zaslon.title_7.text.row_1.2.4")}</li>
                                </ul>
                                <p className="table_title">{t("zaslon.title_7.text.row_2.1")}</p>
                                <ul>
                                    <li>{t("zaslon.title_7.text.row_2.2.1")}</li>
                                    <li>{t("zaslon.title_7.text.row_2.2.2")}</li>
                                    <li>{t("zaslon.title_7.text.row_2.2.3")}</li>
                                    <li>{t("zaslon.title_7.text.row_2.2.4")}</li>
                                </ul>
                                <p className="table_title">{t("zaslon.title_7.text.row_3.1")}</p>
                                <ul>
                                    <li>{t("zaslon.title_7.text.row_3.2.1")}</li>
                                    <li>{t("zaslon.title_7.text.row_3.2.2")}</li>
                                    <li>{t("zaslon.title_7.text.row_3.2.3")}</li>
                                    <li>{t("zaslon.title_7.text.row_3.2.4")}</li>
                                </ul>
                                <p className="table_title">{t("zaslon.title_7.text.row_4.1")}</p>
                                <ul>
                                    <li>{t("zaslon.title_7.text.row_4.2.1")}</li>
                                    <li>{t("zaslon.title_7.text.row_4.2.2")}</li>
                                    <li>{t("zaslon.title_7.text.row_4.2.3")}</li>
                                    <li>{t("zaslon.title_7.text.row_4.2.4")}</li>
                                </ul>
                                <p className="table_title">{t("zaslon.title_7.text.row_5.1")}</p>
                                <ul>
                                    <li>{t("zaslon.title_7.text.row_5.2.1")}</li>
                                    <li>{t("zaslon.title_7.text.row_5.2.2")}</li>
                                    <li>{t("zaslon.title_7.text.row_5.2.3")}</li>
                                    <li>{t("zaslon.title_7.text.row_5.2.4")}</li>
                                </ul>

                            </div>
                        </div>

                        <p className="header_page_center header_page_mini">{t("zaslon.title_8.name")}</p>
                        <div className="main_text">{t("zaslon.title_8.text")}</div>


                        <p className="header_page_center header_page_mini">{t("zaslon.title_5.name")}</p>
                        <div className="main_text">{t("zaslon.title_5.text.1")}</div><br />
                        <div className="main_text">{t("zaslon.title_5.text.2")}</div><br />

                        <div className="main_text table_title">{t("zaslon.title_5.text.3.1.1")}
                            <nobr className='main_text'>{t("zaslon.title_5.text.3.1.2")}</nobr>
                        </div><br />
                        <div className="main_text table_title">{t("zaslon.title_5.text.3.2.1")}
                            <nobr className='main_text'>{t("zaslon.title_5.text.3.2.2")}</nobr>
                        </div><br />
                        <div className="main_text table_title">{t("zaslon.title_5.text.3.3.1")}
                            <nobr className='main_text'>{t("zaslon.title_5.text.3.3.2")}</nobr>
                        </div><br />
                        <div className="main_text table_title">{t("zaslon.title_5.text.3.4.1")}
                            <nobr className='main_text'>{t("zaslon.title_5.text.3.4.2")}</nobr>
                        </div><br />
                        <div className="main_text table_title">{t("zaslon.title_5.text.3.5.1")}
                            <nobr className='main_text'>{t("zaslon.title_5.text.3.5.2")}</nobr>
                        </div><br />
                        <div className="main_text table_title">{t("zaslon.title_5.text.3.6.1")}
                            <nobr className='main_text'>{t("zaslon.title_5.text.3.6.2")}</nobr>
                        </div><br />
                        <div className="main_text table_title">{t("zaslon.title_5.text.3.7.1")}
                            <nobr className='main_text'>{t("zaslon.title_5.text.3.7.2")}</nobr>
                        </div><br />
                        <div className="main_text table_title">{t("zaslon.title_5.text.3.8.1")}
                            <nobr className='main_text'>{t("zaslon.title_5.text.3.8.2")}</nobr>
                        </div>

                        <div className="header_page_center header_page">{t("gallery")}</div>
                        <div className="img_container">
                            <AliceCarousel>
                                {img.map((item, index) =>
                                    <div className='page_img_container'>
                                        <img className="page_img" src={item} alt={img.index} />
                                    </div>

                                )}
                            </AliceCarousel>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Zaslon;
import React, { Component } from 'react';
import "../style/Page.css"
import "../../App.css"
import bur_1 from "./projects_img/bur_1.jpg"
import bur_2 from "./projects_img/bur_2.jpg"
import bur_3 from "./projects_img/bur_3.jpg"
import AliceCarousel from "react-alice-carousel";
import { t } from "i18next";
import Footer from '../Footer';


class Bur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        let img = [bur_1, bur_2, bur_3]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/*<img className="page_main_img" src={acc_img} alt="bur"/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("bur.header")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <p className="header_page_center header_page_mini">{t("bur.title_1.name")}</p>
                        <div className="main_text">{t("bur.title_1.text")}</div>
                        <div className='img_container'>
                            <img className='page_img' src={bur_1}></img>
                        </div>

                        <p className="header_page_center header_page_mini">{t("bur.title_2.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("bur.title_2.text.1")}</div></li>
                            <li><div className="main_text">{t("bur.title_2.text.2")}</div></li>
                            <li><div className="main_text">{t("bur.title_2.text.3")}</div></li>
                            <li><div className="main_text">{t("bur.title_2.text.4")}</div></li>
                            <li><div className="main_text">{t("bur.title_2.text.5")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("bur.title_4.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("bur.title_4.text.1")}</div></li>
                            <li><div className="main_text">{t("bur.title_4.text.2")}</div></li>
                            <li><div className="main_text">{t("bur.title_4.text.3")}</div></li>
                        </ul>

                        <div className="header_page_center header_page">{t("gallery")}</div>
                        <div className="img_container">
                            <AliceCarousel>
                                {img.map((item, index) =>
                                    <div className='page_img_container'>
                                        <img className="page_img" src={item} alt={img.index} />
                                    </div>

                                )}
                            </AliceCarousel>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Bur;
// const React = require('react');
import React from 'react';
import { t } from "i18next";


export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className='wrapper_dark' id='footer'>
                <div className='container'>
                    <div className='contacts'>
                        <div className='contacts-col'>
                            <div className='main_text white'>{t("contact.timetable")}</div>
                            <div className='main_text white'>{t("contact.phoneNumber")}</div>
                            <div className='main_text white'>{t("contact.eMail")}</div>
                            <div className='main_text white'>{t("contact.address")}</div>
                            <br />
                            <div className='main_text white'>{t("contact.name")}</div>
                            <div className='main_text white'>{t("contact.inn")}</div>
                            <div className='main_text white'>{t("contact.okved")}</div>

                        </div>
                        <div className='contacts-col'>
                            <div className='mini-header white'><a href='IntemsPlc' className='white'>{t("current.1.title")}</a></div>
                            <div className='main_text white'>{t("technologies.mini.1")}</div>
                            <div className='main_text white'>{t("technologies.mini.2")}</div>
                            <div className='main_text white'>{t("technologies.mini.3")}</div>
                            <div className='main_text white'>{t("technologies.mini.4")}</div>
                            <div className='main_text white'>{t("technologies.mini.5")}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


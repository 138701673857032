// const React = require('react');
import React from 'react';
import './style/Clients.css'
import './style/Documents.css'
import './UI/Carousel.css'
import pdf from "../img/documents/pdf.png";
import download from "../img/documents/download.png";
import { t } from "i18next";
import "i18next";

export default class Documents extends React.Component {

    render() {
        let clients = [{ img: pdf, href: '', title: t('documents.1.name'), }
        ]
        return (
            <div className='clients-container'>
                <div className='clients' >
                    {clients.map((item, index) => <a key={index} target="_blank" rel="noopener noreferrer" >
                        <div className='document' >
                            <img className='download-img' src={download} alt="download" width='160px' />
                            <img className='img' src={item.img} alt={item.uuid} width='60px' />
                            <div className='title-document'>{item.title}</div>
                        </div>
                    </a>
                    )}
                </div>
            </div>
        );
    }
}


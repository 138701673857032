import React, { Component } from 'react';
import "../style/Page.css"
import "../../App.css"
import pay_1 from "./projects_img/pay_1.svg";
import pay_2 from "./projects_img/pay_2.png";
import { t } from "i18next";
import Footer from '../Footer.jsx';
import AliceCarousel from "react-alice-carousel";


class PaySystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        let img = [pay_1, pay_2]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/*<img className="page_main_img" src={acc_img} alt="paySystem"/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("paySystem.header")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <p className="header_page_center header_page_mini">{t("paySystem.title_1.name")}</p>
                        <div className="main_text">{t("paySystem.title_1.text")}</div>
                        <div className='img_container'>
                            <img className='page_img' src={pay_1}></img>
                        </div>


                        <p className="header_page_center header_page_mini">{t("paySystem.title_2.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                    <tr>
                                        <td className="table_title">{t("paySystem.title_2.text.row_1.1")}</td>
                                        <td>{t("paySystem.title_2.text.row_1.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("paySystem.title_2.text.row_2.1")}</td>
                                        <td>{t("paySystem.title_2.text.row_2.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("paySystem.title_2.text.row_3.1")}</td>
                                        <td>{t("paySystem.title_2.text.row_3.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("paySystem.title_2.text.row_4.1")}</td>
                                        <td>{t("paySystem.title_2.text.row_4.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("paySystem.title_2.text.row_5.1")}</td>
                                        <td>{t("paySystem.title_2.text.row_5.2")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <p className="table_title">{t("paySystem.title_2.text.row_1.1")}</p>
                                {t("paySystem.title_2.text.row_1.2")}
                                <p className="table_title">{t("paySystem.title_2.text.row_2.1")}</p>
                                {t("paySystem.title_2.text.row_2.2")}
                                <p className="table_title">{t("paySystem.title_2.text.row_3.1")}</p>
                                {t("paySystem.title_2.text.row_3.2")}
                                <p className="table_title">{t("paySystem.title_2.text.row_4.1")}</p>
                                {t("paySystem.title_2.text.row_4.2")}
                                <p className="table_title">{t("paySystem.title_2.text.row_5.1")}</p>
                                {t("paySystem.title_2.text.row_5.2")}
                                
                            </div>
                        </div>

                        <p className="header_page_center header_page_mini">{t("paySystem.title_3.name")}</p>
                        <ul>
                            <li><div className="table_title main_text">{t("paySystem.title_3.text.1.1")}<div className="main_text">{t("paySystem.title_3.text.1.2")}</div></div></li>
                            <li><div className="table_title main_text">{t("paySystem.title_3.text.2.1")}<div className="main_text">{t("paySystem.title_3.text.2.2")}</div></div></li>
                            <li><div className="table_title main_text">{t("paySystem.title_3.text.3.1")}<div className="main_text">{t("paySystem.title_3.text.3.2")}</div></div></li>
                            <li><div className="table_title main_text">{t("paySystem.title_3.text.4.1")}<div className="main_text">{t("paySystem.title_3.text.4.2")}</div></div></li>
                            <li><div className="table_title main_text">{t("paySystem.title_3.text.5.1")}</div></li>
                            <li><div className="table_title main_text">{t("paySystem.title_3.text.6.1")}</div></li>
                            <li><div className="table_title main_text">{t("paySystem.title_3.text.7.1")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("paySystem.title_4.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("paySystem.title_4.text.1")}</div></li>
                            <li><div className="main_text">{t("paySystem.title_4.text.2")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("paySystem.title_6.name")}</p>
                        <div className="main_text">{t("paySystem.title_6.text")}</div>

                        {/*<p className="header_page_center header_page_mini">{t("paySystem.title_11.name")}</p>
                        <div className="main_text table_title">{t("paySystem.title_11.text.1")}</div>
                        <div className="block_container">
                            <div>
                                <a href="#"
                                    className="modal-btn download_btn"><i
                                        className="fa fa-download" aria-hidden="true"></i>{t("plc.title_doc.general")}</a>
                            </div>
                        </div>
                        <div className="main_text table_title">{t("paySystem.title_11.text.2")}</div>
                        <div className="block_container">
                            <div>
                                <a href="#"
                                    className="modal-btn download_btn"><i
                                        className="fa fa-download" aria-hidden="true"></i>{t("plc.title_doc.general")}</a>
                            </div>
                        </div>*/}



                        <div className="header_page_center header_page">{t("gallery")}</div>
                        <div className="img_container">
                            <AliceCarousel>
                                {img.map((item, index) =>
                                    <div className='page_img_container'>
                                        <img className="page_img" src={item} alt={img.index} />
                                    </div>

                                )}
                            </AliceCarousel>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default PaySystem;
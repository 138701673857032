// const React = require('react');
import React from 'react';
import './style/Clients.css';
import './UI/Carousel.css';
import "i18next";
import { t } from "i18next";
import logo_1 from "../img/clients/signal.svg";
import logo_2 from "../img/clients/mir.png";
import logo_3 from "../img/clients/semargl.svg";
import logo_4 from "../img/clients/vendex.png";
import logo_5 from "../img/clients/lpm.svg";
import logo_6 from "../img/clients/fdt.png";
import logo_7 from "../img/clients/imis.jpg";
import logo_8 from "../img/clients/kubiki.png";
import logo_9 from "../img/clients/leti.png";
import logo_10 from "../img/clients/ITMO.png";
import logo_11 from "../img/clients/mechnikova.png";
import logo_12 from "../img/clients/biometricLabs.png";
import logo_13 from "../img/clients/allpod.png";
import logo_14 from "../img/clients/tmpd.png";

export default class Clients extends React.Component {

    render() {
    let clients = [{img: logo_1, href: 'https://ntosignal.ru/', name: t('client.signal.name')},
        {img: logo_2, href: 'https://mir.dev/', name: t('client.mir.name')},
        {img: logo_3, href: 'https://semargl.pro/', name: t('client.semargl.name')},
        {img: logo_4, href: 'http://vendexretail.ru/', name: t('client.vendex.name')},
        {img: logo_5, href: 'http://complex-light.ru/', name: t('client.complex-light.name')},
        {img: logo_6, href: 'https://www.fdt.link/', name: t('client.fdt.name')},
        {img: logo_7, href: 'https://www.imismoto.ru/imismoto', name: t('client.imis.name')},
        {img: logo_8, href: 'https://kubiky.com/', name: t('client.kubiky.name')},
        {img: logo_9, href: 'https://etu.ru/', name: t('client.etu.name')},
        {img: logo_10, href: 'https://itmo.ru/', name: t('client.itmo.name')},
        {img: logo_11, href: 'https://szgmu.ru/rus/s/17/o_szgmu.html', name: t('client.szgmu.name')},
        {img: logo_12, href: 'http://biometriclabs.ru', name: t('client.biometric.name')},
        {img: logo_13, href: 'https://allpod.ru', name: t('client.allpod.name')},
        {img: logo_14, href: 'https://tmpd.tech', name: t('client.tmpd.name')}, ]
        return (
            <div className='clients-container'>
                <div className='clients' >
                    {clients.map((item, index)=> <a  key={index} target="_blank" rel="noopener noreferrer" >
                            <div className='client' >
                                <img className='client-img' src={item.img} alt={item.uuid} width='160px'/>
                                <div className='main_text client-name'>{item.name}</div>
                            </div>
                    </a>
                        )}
                </div>
            </div>
        );
    }
}


import React, { Component } from 'react';
import "../style/Page.css"
import "../../App.css"
import air_mono_img from "../Projects/projects_img/air_img.png"
import air_1 from "../Projects/projects_img/air_1.png"
import air_2 from "../Projects/projects_img/air_2.png"
import { t } from "i18next";
import Footer from '../Footer.jsx';
import AliceCarousel from "react-alice-carousel";

class AirPortal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        let img = [air_1, air_2, air_mono_img]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/*<img className="page_main_img" src={acc_img} alt="airPortal"/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("airPortal.header")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <p className="header_page_center header_page_mini">{t("airPortal.title_1.name")}</p>
                        <div className="main_text">{t("airPortal.title_1.text")}</div>
                        <div className='img_container'>
                            <img className='page_img' src={air_mono_img}></img>
                        </div>

                        <p className="header_page_center header_page_mini">{t("airPortal.title_3.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("airPortal.title_3.text.1")}</div></li>
                            <li><div className="main_text">{t("airPortal.title_3.text.2")}</div></li>
                            <li><div className="main_text">{t("airPortal.title_3.text.3")}</div></li>
                            <li><div className="main_text">{t("airPortal.title_3.text.4")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("airPortal.title_6.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("airPortal.title_6.text.2")}</div></li>
                            <li><div className="main_text">{t("airPortal.title_6.text.3")}</div></li>
                            <li><div className="main_text">{t("airPortal.title_6.text.4")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("airPortal.title_7.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                    <tr>
                                        <td className="table_title">{t("airPortal.title_7.text.row_1.1")}</td>
                                        <td>{t("airPortal.title_7.text.row_1.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("airPortal.title_7.text.row_2.1")}</td>
                                        <td>{t("airPortal.title_7.text.row_2.2")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <p className="table_title">{t("airPortal.title_7.text.row_1.1")}</p>
                                {t("airPortal.title_7.text.row_1.2")}
                                <p className="table_title">{t("airPortal.title_7.text.row_2.1")}</p>
                                {t("airPortal.title_7.text.row_2.2")}
                            </div>
                        </div>

                        <p className="header_page_center header_page_mini">{t("airPortal.title_8.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                    <tr>
                                        <td className="table_title">{t("airPortal.title_8.text.row_1.1")}</td>
                                        <td>
                                            <ul>
                                                <li>{t("airPortal.title_8.text.row_1.2.1")}</li>
                                                <li>{t("airPortal.title_8.text.row_1.2.2")}</li>
                                                <li>{t("airPortal.title_8.text.row_1.2.3")}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("airPortal.title_8.text.row_2.1")}</td>
                                        <td>
                                            <ul>
                                                <li>{t("airPortal.title_8.text.row_2.2.1")}</li>
                                                <li>{t("airPortal.title_8.text.row_2.2.2")}</li>
                                                <li>{t("airPortal.title_8.text.row_2.2.3")}</li>
                                                <li>{t("airPortal.title_8.text.row_2.2.4")}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("airPortal.title_8.text.row_3.1")}</td>
                                        <td>
                                            <ul>
                                                <li>{t("airPortal.title_8.text.row_3.2.1")}</li>
                                                <li>{t("airPortal.title_8.text.row_3.2.2")}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <p className="table_title">{t("airPortal.title_8.text.row_1.1")}</p>
                                <ul>
                                    <li>{t("airPortal.title_8.text.row_1.2.1")}</li>
                                    <li>{t("airPortal.title_8.text.row_1.2.2")}</li>
                                    <li>{t("airPortal.title_8.text.row_1.2.3")}</li>
                                </ul>
                                <p className="table_title">{t("airPortal.title_8.text.row_2.1")}</p>
                                <ul>
                                    <li>{t("airPortal.title_8.text.row_2.2.1")}</li>
                                    <li>{t("airPortal.title_8.text.row_2.2.2")}</li>
                                    <li>{t("airPortal.title_8.text.row_2.2.3")}</li>
                                    <li>{t("airPortal.title_8.text.row_2.2.3")}</li>
                                </ul>
                                <p className="table_title">{t("airPortal.title_8.text.row_3.1")}</p>
                                <ul>
                                    <li>{t("airPortal.title_8.text.row_3.2.1")}</li>
                                    <li>{t("airPortal.title_8.text.row_3.2.2")}</li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="header_page_center header_page">{t("gallery")}</div>
                        <div className="img_container">
                            <AliceCarousel>
                                {img.map((item, index) =>
                                    <div className='page_img_container'>
                                        <img className="page_img" src={item} alt={img.index} />
                                    </div>

                                )}
                            </AliceCarousel>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default AirPortal;
import React, { Component } from 'react';
import "../style/Page.css"
import "../../App.css"
import acc_img from "../Projects/projects_img/acc_meter_img.png"
import acc1 from "../Projects/projects_img/acc1.png"
import acc2 from "../Projects/projects_img/acc2.png"
import acc3 from "../Projects/projects_img/acc3.png"
import acc4 from "../Projects/projects_img/acc4.png"
import acc5 from "../Projects/projects_img/acc5.png"
import AliceCarousel from "react-alice-carousel";
import { t } from "i18next";
import Footer from '../Footer';


class AccelerationMeter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        let img = [acc1, acc2, acc3, acc4, acc5]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/*<img className="page_main_img" src={acc_img} alt="accMeter"/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("accMeter.header")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <p className="header_page_center header_page_mini">{t("accMeter.title_1.name")}</p>
                        <div className="main_text">{t("accMeter.title_1.text")}</div>
                        <div className='img_container'>
                            <img className='page_img' src={acc2}></img>
                        </div>


                        <p className="header_page_center header_page_mini">{t("accMeter.title_2.name")}</p>
                        <div className="main_text text table_page_container">
                            <table className="table_page">
                                <tbody>
                                    <tr>
                                        <td className="table_title">{t("accMeter.title_2.text.row_1.1")}</td>
                                        <td>{t("accMeter.title_2.text.row_1.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("accMeter.title_2.text.row_2.1")}</td>
                                        <td>{t("accMeter.title_2.text.row_2.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("accMeter.title_2.text.row_3.1")}</td>
                                        <td>{t("accMeter.title_2.text.row_3.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("accMeter.title_2.text.row_4.1")}</td>
                                        <td>{t("accMeter.title_2.text.row_4.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("accMeter.title_2.text.row_5.1")}</td>
                                        <td>{t("accMeter.title_2.text.row_5.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("accMeter.title_2.text.row_6.1")}</td>
                                        <td>{t("accMeter.title_2.text.row_6.2")}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="table_line">
                                            <hr className="border"></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table_title">{t("accMeter.title_2.text.row_7.1")}</td>
                                        <td>{t("accMeter.title_2.text.row_7.2")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="list_page">
                                <p className="table_title">{t("accMeter.title_2.text.row_1.1")}</p>
                                {t("accMeter.title_2.text.row_1.2")}
                                <p className="table_title">{t("accMeter.title_2.text.row_2.1")}</p>
                                {t("accMeter.title_2.text.row_2.2")}
                                <p className="table_title">{t("accMeter.title_2.text.row_3.1")}</p>
                                {t("accMeter.title_2.text.row_3.2")}
                                <p className="table_title">{t("accMeter.title_2.text.row_4.1")}</p>
                                {t("accMeter.title_2.text.row_4.2")}
                                <p className="table_title">{t("accMeter.title_2.text.row_5.1")}</p>
                                {t("accMeter.title_2.text.row_5.2")}
                                <p className="table_title">{t("accMeter.title_2.text.row_6.1")}</p>
                                {t("accMeter.title_2.text.row_6.2")}
                                <p className="table_title">{t("accMeter.title_2.text.row_7.1")}</p>
                                {t("accMeter.title_2.text.row_7.2")}
                            </div>
                        </div>

                        <p className="header_page_center header_page_mini">{t("accMeter.title_3.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("accMeter.title_3.text.1")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_3.text.2")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_3.text.3")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_3.text.4")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_3.text.5")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("accMeter.title_4.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("accMeter.title_4.text.1")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_4.text.2")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_4.text.3")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("accMeter.title_5.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("accMeter.title_5.text.1")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_5.text.2")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_5.text.3")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("accMeter.title_6.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("accMeter.title_6.text.1")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_6.text.2")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_6.text.3")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("accMeter.title_7.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("accMeter.title_7.text.1")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_7.text.2")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_7.text.3")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("accMeter.title_8.name")}</p>
                        <div className="main_text">{t("accMeter.title_8.text")}</div>

                        <p className="header_page_center header_page_mini">{t("accMeter.title_9.name")}</p>
                        <ul>
                            <li><div className="main_text">{t("accMeter.title_9.text.1")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_9.text.2")}</div></li>
                            <li><div className="main_text">{t("accMeter.title_9.text.3")}</div></li>
                        </ul>

                        <p className="header_page_center header_page_mini">{t("accMeter.title_10.name")}</p>
                        <div className="main_text">{t("accMeter.title_10.text")}</div>
                        
                      
                        
                        <div className="header_page_center header_page">{t("gallery")}</div>
                        <div className="img_container">
                            <AliceCarousel>
                                {img.map((item, index) =>
                                    <div className='page_img_container'>
                                        <img className="page_img" src={item} alt={img.index} />
                                    </div>

                                )}
                            </AliceCarousel>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default AccelerationMeter;